<template>
 <div>
  <ExpertStatisticsTemplate
   :periodSelector="periodSelector"
   @timeSelector="selectedtime = $event"
   :elements="extensions"
   :clearTimeSelector="clearTimeSelector"
   @selector="
    $emit('update:selector', $event),
     $emit('setSelector', $event),
     $store.dispatch('pbxPeriodSelected', $event)
   "
   @setPeriod="(...args) => setPeriod(...args)"
   :startDate="startDate"
   :endDate="endDate"
   @activeBanner="$emit('activeBanner')"
   @successMsg="$emit('successMsg', $event)"
   @success="$emit('success', $event)"
   :urlType="urlType"
   @setLoading="$emit('setLoading', $event)"
  >
   <template v-slot:selector>
    <PbxDashboardSelector
     :key="pbxElements"
     @selector="period = $event"
     @button-selector="(...args) => getSelectedButton(...args)"
     :selectHostname="selectHostname"
     :urlType="urlType"
     @queueName="selectedQueue = $event"
     :buttonSelected="buttonSelected"
     :elements="selectedElement"
     @timeSelector="selectedtime = $event"
     @concatenate="concatenateData = $event"
     :groupDatesQueueReport="groupData"
     :concatenateData="concatenateData"
     :elementsLength="extensions.length"
     :pbxElements="pbxElements"
    />
   </template>
   <template v-slot:content>
    <PbxHeader
     v-model:search="search"
     pbxMenuType="queueDetail"
     :periodSelector="period"
     :parseDateRange="parseDateRange"
     @select-hostname="selectHostname = $event"
     :selectedQueue="selectedQueue"
     :parseTimeRange="parseTimeRange"
     :timeSelected="selectedtime"
     :buttonSelected="buttonSelected"
     @activeBanner="(activeBanner = true), (key = !key)"
     @successMsg="successMsg = $event"
     @success="isSuccess = $event"
     urlType="queue"
    />

    <div class="" v-if="tableData?.length || tableDataConcatenated?.length">
     <div class="overflow-hidden border-gray-200 rounded-md">
      <FullReportTableUsers
       :groupData="groupData"
       :currentSortDir="currentSortDir"
       :tableData="concatenateData ? tableDataConcatenated : filteredData"
       :header="tableHeader"
       :body="tableBody"
       :selectedtime="selectedtime"
       :extensionsArray="extensionsArray"
       v-model:searchExtension="searchExtension"
       :period="period"
       :parseDateRange="parseDateRange"
       :queuesOnlyStats="queuesOnlyStats"
       :timeRange="timeRange"
       :timeGap="timeGap"
       :numberOfColumns="numberOfColumns"
       :selectedRangeType="selectedRangeType"
       :concatenateData="concatenateData"
       :extensionsNameNumberArray="extensionsNameNumberArray"
       @sort="sort($event)"
      />
     </div>
    </div>
    <div v-else class="pt-20">
     <div class="text-center">
      <svg
       xmlns="http://www.w3.org/2000/svg"
       class="mx-auto h-12 w-12 text-gray-400"
       fill="none"
       viewBox="0 0 24 24"
       stroke="currentColor"
      >
       <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z"
       />
      </svg>
      <h3 class="mt-2 text-lg font-medium text-gray-900">
       {{
        this.pbxElements && this.pbxElements.length > 0
         ? $t("noDataFoundTitle")
         : $t("selectElementFromList")
       }}
      </h3>
     </div>
    </div>
   </template>
  </ExpertStatisticsTemplate>
 </div>

 <!-- <Loading
   v-model:active="isLoading"
   :can-cancel="true"
   :on-cancel="onCancel"
   :is-full-page="fullPage"
  /> -->
</template>

<script>
import PbxHeader from "../../components/PBX/PbxHeader.vue";
import PbxDashboardSelector from "../../components/PBX/PbxDashboardSelector.vue";
import ExpertStatisticsTemplate from "./ExpertStatisticsTemplate.vue";
import axios from "axios";
import { mapGetters } from "vuex";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import PbxDashboard from "../../components/PBX/PbxDashboard.vue";
import FullReportTableUsers from "../../components/PBX/FullReportTableUsers.vue";
import EmptyStateDashboard from "../../components/PBX/EmptyStateDashboard.vue";
import {
 Switch,
 SwitchGroup,
 SwitchLabel,
 Menu,
 MenuButton,
 MenuItem,
 MenuItems,
} from "@headlessui/vue";
import { ChevronDownIcon, XIcon } from "@heroicons/vue/outline";
import {
 ArrowCircleRightIcon,
 ArrowCircleLeftIcon,
 InformationCircleIcon,
} from "@heroicons/vue/solid";

export default {
 props: ["presentationKey"],
 components: {
  Loading,
  PbxDashboard,
  Switch,
  SwitchGroup,
  SwitchLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  ChevronDownIcon,
  ArrowCircleRightIcon,
  ArrowCircleLeftIcon,
  InformationCircleIcon,
  XIcon,
  FullReportTableUsers,
  ExpertStatisticsTemplate,
  PbxDashboardSelector,
  PbxHeader,
 },
 data() {
  return {
   currentSortDir: "asc",
   currentSort: "",
   selectedRangeType: "waiting",
   selectedRangeTypeText: "Durées d'attente",
   groupData: true,
   searchQueue: null,
   searchQueueName: null,
   searchExtension: null,
   searchExtensionName: null,
   queuesNameArray: [],
   queuesArray: [],
   extensionsNameArray: [],
   extensionsNameNumberArray: [],
   queuesNameNumberArray: [],
   extensionsArray: [],
   queuesOnlyStats: true,
   data: {},
   selectedtime: undefined,
   fullPage: true,
   selectedElement: "",
   selectedQueue: "",
   period: "",
   selectHostname: "",
   queueData: [],
   extensionData: [],
   textMonth: "",
   buttonSelected: "month",
   successMsg: "",
   activeBanner: false,
   isSuccess: true,
   key: false,
   concatenateData: false,
   startDate: null,
   endDate: null,
   tableDataConcatenated: null,
   tableData: null,
   tableHeader: null,
   tableBody: null,
   extensions: [],
   pbxMap: null,
   dns: null,
   pbxElements: null,
  };
 },
 methods: {
  getSelectedButton(...args) {
   this.buttonSelected = args[0];
   this.textMonth = args[1];
  },
  async getData() {
   try {
    const res = await axios.get(
     `https://xp-stats-201.bluerock.tel/api/v1.2/reports/${this.presentationKey}`
    );
    console.log(res.data);
    return res.data;
   } catch (error) {
    this.$emit("setLoading", false);
    this.$emit("error404", error.response);
    this.errorHandling(error);
   }
  },

  async setTableData() {
   this.$emit("setLoading", true);
   const getData = await this.getData();
   let data = getData?.data;
   let parameters = getData?.parameters;

   this.pbxMap = getData?.map ? getData.map : null;
   this.dns = parameters?.dns?.split(",");
   this.startDate = parameters?.start;
   this.endDate = parameters?.end;
   this.urlType = parameters.element_type == 4 ? "queue" : "extension";
   this.pbxElements = parameters?.dns?.split(",");

   this.periodSelector = this.formatPeriod(parameters.start, parameters.end);

   this.$store.dispatch("hostName", parameters.host_name);

   //  this.$store.dispatch("dataSet", parameters.report_type);

   this.extensions =
    parameters && parameters.dns ? parameters.dns.split(",") : [];

   if (
    parameters.time &&
    (!this.selectedtime || this.selectedtime.length <= 0)
   ) {
    let splitTime = parameters.time.split("-");
    this.selectedtime[0] = splitTime[0];
    this.selectedtime[1] = splitTime[1];
   }
   if (this.selectedtime && this.selectedtime.length > 1) {
    queueReportDataUngrouped = queueReportData["all"];
   }

   this.tableData = [];
   this.tableDataConcatenated = [];
   this.$emit("setLoading", true);
   this.$store.dispatch("pbxElementType", this.urlType);
   console.log("setTableData ~ data:", data);
   if (
    this.pbxMap &&
    this.pbxMap["call_queues"] &&
    this.pbxMap["extensions"] &&
    this.dns
   ) {
    this.selectedElement = this.dns?.map(res => {
     return (
      res +
      (this.urlType === "queue"
       ? this.pbxMap["call_queues"][res]
         ? "-" + this.pbxMap["call_queues"][res]["name"]
         : ""
       : this.pbxMap["extensions"][res]
       ? "-" + this.pbxMap["extensions"][res]
       : "")
     );
    });
   }
   this.tableHeader = [
    { text: "Utilisateur", info: "", hasInfo: false, key: "user" },
    {
     text: "Appels Sortants",
     hasInfo: false,
    },
    {
     text: "Appels Entrants",
     hasInfo: false,
    },
    {
     text: "Appels Internes",
     hasInfo: false,
    },
    {
     text: "Total des Appels Répondus",
     hasInfo: false,
    },
   ];
   this.tableBody = ["user", "outbound", "inbound", "internal", "total"];
   if (data?.length) {
    this.tableData = data;
    this.tableDataConcatenated[0] = this.consolidateData(data);
    this.extensionsNameNumberArray = data.map(res => res.user);
   }
   this.$emit("setLoading", false);
  },
  consolidateData(data) {
   const consolidated = {
    user: " - ", // Mark user as '-' for the consolidated row
    outbound_answered: 0,
    outbound_talking_duration_total: 0,
    outbound_talking_duration_avg: 0,
    inbound_answered: 0,
    inbound_talking_duration_total: 0,
    inbound_talking_duration_avg: 0,
    inbound_unanswered: 0,
    inbound_answered_percentage: 0,
    inbound_waiting_duration_avg: 0,
    internal_answered: 0,
    internal_talking_duration_total: 0,
    internal_talking_duration_avg: 0,
    answered_calls: 0,
    duration_total: 0,
    duration_avg: 0,
   };

   const numEntries = data.length;

   data.forEach(row => {
    // Convert string values to integers
    consolidated.outbound_answered += parseInt(row.outbound_answered, 10);
    consolidated.outbound_talking_duration_total += parseInt(
     row.outbound_talking_duration_total,
     10
    );
    consolidated.inbound_answered += parseInt(row.inbound_answered, 10);
    consolidated.inbound_talking_duration_total += parseInt(
     row.inbound_talking_duration_total,
     10
    );
    consolidated.inbound_unanswered += parseInt(row.inbound_unanswered, 10);
    consolidated.internal_answered += parseInt(row.internal_answered, 10);
    consolidated.internal_talking_duration_total += parseInt(
     row.internal_talking_duration_total,
     10
    );
    consolidated.answered_calls += parseInt(row.answered_calls, 10);
    consolidated.duration_total += parseInt(row.duration_total, 10);

    // Summing percentages and averages
    consolidated.inbound_waiting_duration_avg +=
     row.inbound_waiting_duration_avg;
    consolidated.outbound_talking_duration_avg +=
     row.outbound_talking_duration_avg;
    consolidated.inbound_talking_duration_avg +=
     row.inbound_talking_duration_avg;
    consolidated.internal_talking_duration_avg +=
     row.internal_talking_duration_avg;
    consolidated.duration_avg += row.duration_avg;
   });

   // Calculate averages where needed
   consolidated.inbound_answered_percentage = (
    (consolidated.inbound_answered /
     (consolidated.inbound_answered + consolidated.inbound_unanswered)) *
    100
   ).toFixed(0);
   consolidated.inbound_waiting_duration_avg /= numEntries;
   consolidated.outbound_talking_duration_avg /= numEntries;
   consolidated.inbound_talking_duration_avg /= numEntries;
   consolidated.internal_talking_duration_avg /= numEntries;
   consolidated.duration_avg /= numEntries;

   return consolidated;
  },
  sort(s) {
   if (s === this.currentSort) {
    this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
   }
   this.currentSort = s;
  },
  sortedData() {
   const extensionData = this.tableData;
   let extensionDataSorted = extensionData.sort((a, b) => {
    let toBeSorted = this.currentSort;
    let modifier = 1;
    if (this.currentSortDir === "desc") modifier = -1;
    if (parseInt(a[toBeSorted]) < parseInt(b[toBeSorted])) return -1 * modifier;
    if (parseInt(a[toBeSorted]) > parseInt(b[toBeSorted])) return 1 * modifier;
    return 0;
   });
   return extensionDataSorted;
  },
  parseDateRange(date) {
   if (date.length === 8) {
    let year = ("0" + date).slice(1, -4);
    let month = ("0" + date).slice(-4, -2);
    let day = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = day + "/" + month + "/" + year;
    return String(fullDay);
   } else if (date.length === 6) {
    let year = ("0" + date).slice(1, 5);
    let month = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = month + "/" + year;
    return String(fullDay);
   } else if (date.length === 17) {
    let fullDay = date.split("-");
    let firstYear = ("0" + fullDay[0]).slice(1, 5);
    let firstMonth = ("0" + fullDay[0]).slice(-4, -2);
    let firstDay = ("0" + fullDay[0]).slice(-2);

    let firstFullDay = "";
    firstFullDay = firstDay + "/" + firstMonth + "/" + firstYear;

    let lastYear = ("0" + fullDay[1]).slice(1, 5);
    let lastMonth = ("0" + fullDay[1]).slice(-4, -2);
    let lastDay = ("0" + fullDay[1]).slice(-2);

    let lastFullDay = "";
    lastFullDay = lastDay + "/" + lastMonth + "/" + lastYear;
    let finalDay = "";
    finalDay = firstFullDay + " - " + lastFullDay;
    return String(finalDay);
   } else {
    return date;
   }
  },
  parseTimeRange(time) {
   if (time && time.length > 0) {
    const timeStart = time[0].slice(0, 2) + ":" + time[0].slice(2);
    const timeEnd = time[1].slice(0, 2) + ":" + time[1].slice(2);
    const finalTime = timeStart + " - " + timeEnd;
    return String(finalTime);
   }
  },
 },
 watch: {},
 mounted() {
  this.setTableData();
 },
 computed: {
  filteredData() {
   return this.sortedData().filter(data => {
    if (this.searchExtension?.length > 0) {
     let flag = false;
     this.searchExtension.forEach(user => {
      if (user.includes(data.user)) {
       flag = true;
      }
     });
     return flag;
    }
    return data;
   });
  },
  ...mapGetters([
   "hostName",
   "hostTimeZone",
   "pbxPeriodSelected",
   "pbxMap",
   "pbxElementType",
  ]),
 },
};
</script>

<style>
.switch-group {
 position: absolute;
 right: 1.3rem;
 top: 7.5rem;
}
.switch-group-no-info {
 position: absolute;
 right: 1.3rem;
 top: 7.5rem;
}
</style>
