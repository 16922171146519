<template>
  <div class="">
   <div
    v-if="assignedFilterExtension && assignedFilterExtension.length"
    class="flex gap-2 pt-1">
    <span
     v-for="search in assignedFilterExtension"
     :key="search"
     class="inline-flex items-center rounded-full bg-gray-200 py-0.5 pl-2.5 pr-1 text-sm font-medium text-gray-700">
     {{ search }}
     <button
      type="button"
      @click="
       assignedFilterExtension && assignedFilterExtension.length > 1
        ? assignedFilterExtension.splice(
           assignedFilterExtension.indexOf(search),
           1
          )
        : ((assignedFilterExtension.length = 0),
          this.$emit('update:searchExtension', null))
      "
      class="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500 focus:text-white focus:outline-none">
      <svg class="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
       <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
      </svg>
     </button>
    </span>
   </div>
  </div>
  <div class="flex flex-col">
   <div class="overflow-x-auto">
    <div class="inline-block min-w-full align-middle py-2 px-2">
     <div
      class="relative overflow-hidden shadow-md ring-1 ring-black ring-opacity-5 md:rounded-lg">
      <!-- <div
       v-if="selectedPeople.length > 0"
       class="absolute top-0 left-12 flex h-12 items-center space-x-3 bg-gray-50 dark:bg-slate-700 sm:left-16"
      >
       <button
        type="button"
        class="inline-flex items-center rounded border border-gray-300 bg-white dark:bg-slate-500 px-2.5 py-1.5 text-xs font-medium shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
       >
        Bulk edit
       </button>
       <button
        type="button"
        class="inline-flex items-center rounded border border-gray-300 bg-white dark:bg-slate-500 px-2.5 py-1.5 text-xs font-medium shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
       >
        Delete all
       </button>
      </div> -->
      <table
       v-if="tableData && tableData.length"
       class="min-w-full divide-y divide-gray-300 dark:divide-slate-400 h-96">
       <thead class="bg-gray-50 dark:bg-slate-700">
        <tr class="">
         <th
          v-for="head in header"
          :key="head"
          scope="col"
          :class="[
           table_head_shadow.indexOf(head.text) >= 0 ? 'bg-gray-100 border-l border-r border-gray-200' : '',
           'px-2 py-3 text-center text-sm font-semibold',
          ]">
          <div
           @click="
            head.key &&
            head.text !== 'talking_duration' &&
            head.text !== 'waiting_duration'
             ? setSorting(head.key)
             : ''
           "
           :class="
            head.key === this.activeSorting
             ? `underline`
             : head.text === 'queue' ||
               head.text === 'queue_name' ||
               head.text === 'Utilisateur' ||
               head.text === 'element_name'
             ? ''
             : 'flex w-auto text-center justify-center'
           "
           :title="head.info">
           <div class="w-28 text-center" v-if="head.text === 'Utilisateur'">
            <div as="div" class="mt-0">
             <div class="flex text-center justify-center">
              <button
               class="flex text-center justify-center items-center text-sm font-semibold capitalize"
               @focus="openExtensionFilter = true"
               @blur="openExtensionFilter = false"
               @click="focusInputExtension()">
               {{ head.text }}
               <FilterIcon
                class="flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500"
                aria-hidden="true" />
              </button>
             </div>
             <transition
              enter-active-class="transition ease-out duration-100"
              enter-from-class="transform opacity-0 scale-95"
              enter-to-class="transform opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75"
              leave-from-class="transform opacity-100 scale-100"
              leave-to-class="transform opacity-0 scale-95">
              <div
               @mouseover="
                (openExtensionFilterFocus = true), (isMouseOverFilter = true)
               "
               @mouseleave="
                (isMouseOverFilter = false), (openExtensionFilterFocus = false)
               "
               v-show="openExtensionFilter || openExtensionFilterFocus"
               class="rounded origin-top-left absolute mt-2 w-auto shadow-2xl body__header--menu ring-1 ring-black ring-opacity-5 focus:outline-none z-1">
               <div
                class="mt-1 mb-1 mx-1 rounded-md px-3 py-2 shadow-sm text-gray-800">
                <div class="relative">
                 <div
                  class="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center z-2">
                  <SearchIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                 </div>
                 <vue-select
                  @focus="
                   (openExtensionFilter = true),
                    (openExtensionFilterFocus = true)
                  "
                  @blur="openExtensionFilter = false"
                  v-model="assignedFilterExtension"
                  multiple
                  :options="extensionsNameNumberArray"
                  maxHeight="200"
                  close-on-select="true"
                  searchable
                  :class="[
                   'block w-full bg-white py-1 pl-10 pr-3 text-sm sm:text-sm z-1',
                  ]"
                  ref="inputSearchExtension">
                 </vue-select>
                </div>
               </div>
              </div>
             </transition>
            </div>
           </div>
           <div v-else-if="head.text === 'Appels Sortants'" class="w-full">
            <div class="inline-block w-full text-center">
             <div class="pb-2 text-center">
              <span
               class="relative flex justify-center w-full"
               :title="head.info ? head.info : ''">
               Appels Sortants
              </span>
             </div>
 
             <div :class="`lg:grid grid-cols-2 gap-0 text-left`">
              <div
               @click="setSorting('outbound_answered')"
               class="flex justify-center items-center text-green-600"
               :title="head.info ? head.info : ''">
               Répondus
               <ArrowNarrowDownIcon
                v-if="this.currentSortDir === 'desc'"
                :class="[
                 'outbound_answered' === this.activeSorting ? `underline` : '',
                 'flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500',
                ]"
                aria-hidden="true" />
               <ArrowNarrowUpIcon
                v-else-if="this.currentSortDir === 'asc'"
                :class="[
                 'outbound_answered' === this.activeSorting ? `underline` : '',
                 'flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500',
                ]"
                aria-hidden="true" />
              </div>
 
              <div
               class="flex justify-center"
               :title="head.info ? head.info : ''">
               <select
                v-model="talking_duration_option_outbound"
                name="talking_duration_option_outbound"
                class="text-sm py-0.5 px-1 w-60 font-bold border-0 bg-transparent">
                <option value="total">
                 {{ "Durée total" }}
                </option>
                <option value="avg">
                 {{ "Durée moyenne" }}
                </option>
               </select>
              </div>
             </div>
            </div>
           </div>
           <div v-else-if="head.text === 'Appels Entrants'" class="w-full">
            <div class="inline-block w-full text-center">
             <div class="pb-2 text-center">
              <span
               class="relative flex justify-center w-full"
               :title="head.info ? head.info : ''">
               Appels Entrants
              </span>
             </div>
 
             <div :class="`lg:grid grid-cols-5  gap-0 text-left`">
              <div
               @click="setSorting('inbound_answered')"
               class="flex justify-center items-center text-green-600"
               :title="head.info ? head.info : ''">
               Répondus
               <ArrowNarrowDownIcon
                v-if="this.currentSortDir === 'desc'"
                :class="[
                 'inbound_answered' === this.activeSorting ? `underline` : '',
                 'flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500',
                ]"
                aria-hidden="true" />
               <ArrowNarrowUpIcon
                v-else-if="this.currentSortDir === 'asc'"
                :class="[
                 'inbound_answered' === this.activeSorting ? `underline` : '',
                 'flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500',
                ]"
                aria-hidden="true" />
              </div>
              <div
               class="flex justify-center"
               :title="head.info ? head.info : ''">
               <select
                v-model="talking_duration_option_inbound"
                name="talking_duration_option_inbound"
                class="text-sm py-0.5 px-1 w-60 font-bold border-0 bg-transparent">
                <option value="total">
                 {{ "Durée total" }}
                </option>
                <option value="avg">
                 {{ "Durée moyenne" }}
                </option>
               </select>
              </div>
              <div
               @click="setSorting('inbound_unanswered')"
               class="flex justify-center items-center text-red-600"
               :title="head.info ? head.info : ''">
               Non répondus
               <ArrowNarrowDownIcon
                v-if="this.currentSortDir === 'desc'"
                :class="[
                 'inbound_unanswered' === this.activeSorting ? `underline` : '',
                 'flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500',
                ]"
                aria-hidden="true" />
               <ArrowNarrowUpIcon
                v-else-if="this.currentSortDir === 'asc'"
                :class="[
                 'inbound_unanswered' === this.activeSorting ? `underline` : '',
                 'flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500',
                ]"
                aria-hidden="true" />
              </div>
              <div
               @click="setSorting('inbound_answered_percentage')"
               class="flex justify-center items-center"
               :title="head.info ? head.info : ''">
               Taux de réponse
               <ArrowNarrowDownIcon
                v-if="this.currentSortDir === 'desc'"
                :class="[
                 'inbound_answered_percentage' === this.activeSorting
                  ? `underline`
                  : '',
                 'flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500',
                ]"
                aria-hidden="true" />
               <ArrowNarrowUpIcon
                v-else-if="this.currentSortDir === 'asc'"
                :class="[
                 'inbound_answered_percentage' === this.activeSorting
                  ? `underline`
                  : '',
                 'flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500',
                ]"
                aria-hidden="true" />
              </div>
              <div
               @click="setSorting('inbound_waiting_duration_avg')"
               class="flex justify-center items-center"
               :title="head.info ? head.info : ''">
               Attente moyenne
               <ArrowNarrowDownIcon
                v-if="this.currentSortDir === 'desc'"
                :class="[
                 'inbound_waiting_duration_avg' === this.activeSorting
                  ? `underline`
                  : '',
                 'flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500',
                ]"
                aria-hidden="true" />
               <ArrowNarrowUpIcon
                v-else-if="this.currentSortDir === 'asc'"
                :class="[
                 'inbound_waiting_duration_avg' === this.activeSorting
                  ? `underline`
                  : '',
                 'flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500',
                ]"
                aria-hidden="true" />
              </div>
             </div>
            </div>
           </div>
           <div v-else-if="head.text === 'Appels Internes'" class="w-full">
            <div class="inline-block w-full text-center">
             <div class="pb-2 text-center">
              <span
               class="relative flex justify-center"
               :title="head.info ? head.info : ''">
               Appels Internes
              </span>
             </div>
 
             <div :class="`lg:grid grid-cols-2 gap-0 text-left`">
              <div
               @click="setSorting('internal_answered')"
               class="flex justify-center items-center text-green-600"
               :title="head.info ? head.info : ''">
               Répondus
               <ArrowNarrowDownIcon
                v-if="this.currentSortDir === 'desc'"
                :class="[
                 'internal_answered' === this.activeSorting ? `underline` : '',
                 'flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500',
                ]"
                aria-hidden="true" />
               <ArrowNarrowUpIcon
                v-else-if="this.currentSortDir === 'asc'"
                :class="[
                 'internal_answered' === this.activeSorting ? `underline` : '',
                 'flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500',
                ]"
                aria-hidden="true" />
              </div>
 
              <div
               class="flex justify-center"
               :title="head.info ? head.info : ''">
               <select
                v-model="talking_duration_option_internal"
                name="talking_duration_option_internal"
                class="text-sm py-0.5 px-1 w-60 font-bold border-0 bg-transparent">
                <option value="total">
                 {{ "Durée total" }}
                </option>
                <option value="avg">
                 {{ "Durée moyenne" }}
                </option>
               </select>
              </div>
             </div>
            </div>
           </div>
           <div
            v-else-if="head.text === 'Total des Appels Répondus'"
            class="w-full">
            <div class="inline-block w-full text-center">
             <div class="flex justify-center pb-2 text-center items-center">
              <span
               class="relative flex justify-center text-center items-center w-full"
               :title="head.info ? head.info : ''">
               Total des Appels Répondus
              </span>
             </div>
 
             <div :class="`lg:grid grid-cols-2 gap-0 text-left`">
              <div
               @click="setSorting('answered_calls')"
               class="flex justify-center items-center"
               :title="head.info ? head.info : ''">
               Nombre
               <ArrowNarrowDownIcon
                v-if="this.currentSortDir === 'desc'"
                :class="[
                 'answered_calls' === this.activeSorting ? `underline` : '',
                 'flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500',
                ]"
                aria-hidden="true" />
               <ArrowNarrowUpIcon
                v-else-if="this.currentSortDir === 'asc'"
                :class="[
                 'answered_calls' === this.activeSorting ? `underline` : '',
                 'flex-shrink-0 mt-0.5 h-3 w-3 hover:text-gray-500',
                ]"
                aria-hidden="true" />
              </div>
 
              <div
               @click="setSorting('internal_talking_duration_total')"
               class="flex justify-center"
               :title="head.info ? head.info : ''">
               <select
                v-model="talking_duration_option_total"
                name="talking_duration_option_total"
                class="text-sm py-0.5 px-1 w-60 font-bold border-0 bg-transparent">
                <option value="total">
                 {{ "Durée total" }}
                </option>
                <option value="avg">
                 {{ "Durée moyenne" }}
                </option>
               </select>
              </div>
             </div>
            </div>
           </div>
          </div>
         </th>
        </tr>
       </thead>
       <tbody
        class="divide-y divide-gray-200 bg-white dark:bg-slate-500 dark:divide-slate-400">
        <tr
         v-for="data in tableData"
         :key="data.user"
         :class="['hover:bg-slate-100 dark:hover:bg-slate-400 cursor-pointer']">
         <!-- <td class="relative w-12 px-6 sm:w-16 sm:px-8">
          <div
           v-if="selectedPeople.includes(data.id)"
           class="absolute inset-y-0 left-0 w-0.5 bg-indigo-600"
          ></div>
          <input
           type="checkbox"
           class="absolute left-4 top-1/2 -mt-2 h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500 sm:left-6"
           :value="data.id"
           v-model="selectedPeople"
          />
         </td> -->
         <td
          v-for="(info, idx) in body"
          :key="info"
          :class="[
           table_info_shadow.indexOf(info) >= 0
            ? 'bg-gray-100 dark:bg-slate-400 border-l border-r border-gray-200'
            : '',
           'whitespace-nowrap px-1 py-3 text-sm max__body__width truncate text-center',
           ,
          ]">
          <span :class="['rounded-full px-2 py-1']">
           <span v-if="info === 'user'" :title="data[info]" class="">
            {{ data[info] }}
           </span>
           <span
            v-else-if="info === 'internal'"
            class="grid grid-cols-2 text-center">
            <div
             class="flex justify-center text-center items-center"
             title="Appels internes répondus">
             {{ data["internal_answered"] }}
            </div>
            <div class="flex justify-center text-center items-center">
             <span
              v-if="talking_duration_option_internal === 'total'"
              title="Appels internes durée total">
              {{
               data["internal_talking_duration_total"]
                ? this.formatSecsToHourMinAndSecs(
                   data["internal_talking_duration_total"]
                  )
                : 0
              }}
             </span>
             <span v-else title="Appels internes durée moyenne">
              {{
               data["internal_talking_duration_avg"]
                ? this.formatSecsToHourMinAndSecs(
                   data["internal_talking_duration_avg"]
                  )
                : 0
              }}
             </span>
            </div>
           </span>
           <span
            v-else-if="info === 'inbound'"
            class="grid grid-cols-5 text-center">
            <div title="Appels entrants répondus">
             {{ data["inbound_answered"] }}
            </div>
            <div>
             <span
              v-if="talking_duration_option_inbound === 'total'"
              title="Appels entrants durée total">
              {{
               data["inbound_talking_duration_total"]
                ? this.formatSecsToHourMinAndSecs(
                   data["inbound_talking_duration_total"]
                  )
                : 0
              }}
             </span>
             <span v-else title="Appels entrants durée moyenne">
              {{
               data["inbound_talking_duration_avg"]
                ? this.formatSecsToHourMinAndSecs(
                   data["inbound_talking_duration_avg"]
                  )
                : 0
              }}
             </span>
            </div>
            <div title="Appels entrants non répondus">
             {{ data["inbound_unanswered"] }}
            </div>
            <div title="Appels entrants taux de réponse">
             {{
              data["inbound_answered_percentage"] > 0
               ? data["inbound_answered_percentage"] + "%"
               : "-"
             }}
            </div>
            <div title="Appels entrants attente moyenne">
             {{
              data["inbound_waiting_duration_avg"]
               ? this.formatSecsToHourMinAndSecs(
                  data["inbound_waiting_duration_avg"]
                 )
               : 0
             }}
            </div>
           </span>
           <span
            v-else-if="info === 'outbound'"
            class="grid grid-cols-2 text-center"
            title="Appels sortants répondus">
            <div>
             {{ data["outbound_answered"] }}
            </div>
            <div>
             <span
              v-if="talking_duration_option_outbound === 'total'"
              title="Appels sortants durée total">
              {{
               data["outbound_talking_duration_total"]
                ? this.formatSecsToHourMinAndSecs(
                   data["outbound_talking_duration_total"]
                  )
                : 0
              }}
             </span>
             <span v-else title="Appels sortants durée moyenne">
              {{
               data["outbound_talking_duration_avg"]
                ? this.formatSecsToHourMinAndSecs(
                   data["outbound_talking_duration_avg"]
                  )
                : 0
              }}
             </span>
            </div>
           </span>
           <span v-else class="grid grid-cols-2 text-center">
            <div title="Total appels répondus">
             {{ data["answered_calls"] }}
            </div>
            <div>
             <span
              v-if="talking_duration_option_total === 'total'"
              title="Total appels durée total">
              {{ this.formatSecsToHourMinAndSecs(data["duration_total"]) }}
             </span>
             <span v-else title="Total appels durée moyenne">
              {{ this.formatSecsToHourMinAndSecs(data["duration_avg"]) }}
             </span>
            </div>
           </span>
          </span>
         </td>
        </tr>
       </tbody>
       <!-- <tbody
        v-else
        class="divide-y divide-gray-200 bg-white dark:bg-slate-500 dark:divide-slate-400"
       >
        <tr :class="['hover:bg-slate-100 dark:hover:bg-slate-400 cursor-pointer']">
         <div class="text-center p-10">
          <svg
           xmlns="http://www.w3.org/2000/svg"
           fill="none"
           viewBox="0 0 24 24"
           stroke-width="1"
           stroke="currentColor"
           class="mx-auto h-12 w-12 text-gray-400"
          >
           <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M14.25 9.75L16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z"
           />
          </svg>
          <h3 class="mt-2 text-lg font-medium text-gray-900">
           {{ $t("noDataFoundTitle") }}
          </h3>
          <p class="mt-1 text-base text-gray-500"></p>
         </div>
        </tr>
       </tbody> -->
      </table>
      <div v-else class="text-center p-10 bg-white">
       <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1"
        stroke="currentColor"
        class="mx-auto h-12 w-12 text-gray-400">
        <path
         stroke-linecap="round"
         stroke-linejoin="round"
         d="M14.25 9.75L16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z" />
       </svg>
       <h3 class="mt-2 text-lg font-medium text-gray-900">
        {{ $t("noDataFoundTitle") }}
       </h3>
       <p class="mt-1 text-base text-gray-500"></p>
      </div>
      <!-- <BackendTablePagination
       :to="this.to"
       :from="this.from"
       :total="this.total"
       :currentPage="currentPage"
       :links="links"
       @select-page="$emit('selectPage', $event)"
       @select-rows="$emit('selectRows', $event)"
      /> -->
     </div>
    </div>
   </div>
  </div>
 </template>
 
 <script>
 // import BackendTablePagination from "./BackendTablePagination.vue";
 import VueSelect from "vue-next-select";
 import "vue-next-select/dist/index.css";
 
 import {
  FilterIcon,
  ArrowNarrowDownIcon,
  ArrowNarrowUpIcon,
 } from "@heroicons/vue/outline";
 // import { FilterIcon } from "@vue-hero-icons/outline"
 import { MinusSmIcon } from "@heroicons/vue/solid";
 import { mapGetters } from "vuex";
 
 const table_info_shadow = ["outbound", "internal"];
 const table_head_shadow = ["Appels Sortants", "Appels Internes"];
 
 export default {
  props: [
   "header",
   "body",
   "tableData",
   "to",
   "from",
   "total",
   "currentPage",
   "links",
   "selectedPeople",
   "indeterminate",
   "selectedtime",
   "queuesNameArray",
   "queuesArray",
   "extensionsArray",
   "extensionsNameArray",
   "searchQueue",
   "searchQueueName",
   "searchExtension",
   "searchExtensionName",
   "extensionsNameNumberArray",
   "queuesNameNumberArray",
   "period",
   "parseDateRange",
   "queuesOnlyStats",
   "timeRange",
   "timeGap",
   "numberOfColumns",
   "selectedRangeType",
   "concatenateData",
   "currentSortDir",
   "groupData",
  ],
  components: {
   VueSelect,
   FilterIcon,
   MinusSmIcon,
   ArrowNarrowDownIcon,
   ArrowNarrowUpIcon,
  },
  data() {
   return {
    table_head_shadow,
    table_info_shadow,
    talking_duration_option: "avg",
    waiting_duration_option: "avg",
    talking_duration_option_outbound: "avg",
    waiting_duration_option_outbound: "avg",
    talking_duration_option_inbound: "avg",
    waiting_duration_option_inbound: "avg",
    talking_duration_option_internal: "avg",
    waiting_duration_option_internal: "avg",
    talking_duration_option_total: "avg",
    selectedPeople: [],
    assignedFilterQueue: [],
    assignedFilterQueueName: [],
    assignedFilterExtension: [],
    assignedFilterExtensionName: [],
    openQueueFilter: false,
    openQueueFilterFocus: false,
    openQueueNameFilter: false,
    openQueueNameFilterFocus: false,
    openExtensionFilter: false,
    openExtensionFilterFocus: false,
    openExtensionNameFilter: false,
    openExtensionNameFilterFocus: false,
    isMouseOverFilter: false,
    activeSorting: "",
   };
  },
  methods: {
   getColumnColor(info, column, data) {
    if (!this.pbx3cxTableConfig) {
     return false;
    }
    let val = null;
    switch (column) {
     case "answered_percentage":
      val = (data["answered"] / data["calls"]) * 100;
      return this.getAnsweredPercentageColor(val);
     case "waiting_duration":
      val = data["waiting_duration"] / data["calls"];
      return this.getDurationAvgAnswerColor(val);
     case "talking_duration":
      val = data["talking_duration"] / data["answered"];
      return this.getDurationAvgCallColor(val);
     case "calls":
      val = data["solicited"] / data["calls"];
      return this.getRatioSolicitationsColor(val);
     case "solicited":
      val = data["solicited"] / data["calls"];
      return this.getRatioSolicitationsColor(val);
     default:
      return "";
    }
   },
   getAnsweredPercentageColor(value) {
    if (
     value <= this.pbx3cxTableConfig?.answered_percentage_red_value &&
     this.pbx3cxTableConfig?.answered_percentage_red_active
    ) {
     return "text-red-500 bg-red-200 font-semibold";
    } else if (
     value > this.pbx3cxTableConfig?.answered_percentage_red_value &&
     value <= this.pbx3cxTableConfig?.answered_percentage_orange_value &&
     this.pbx3cxTableConfig?.answered_percentage_orange_active
    ) {
     return "text-orange-600 bg-orange-200 font-semibold";
    } else if (
     value > this.pbx3cxTableConfig?.answered_percentage_orange_value &&
     value <= this.pbx3cxTableConfig?.answered_percentage_yellow_value &&
     this.pbx3cxTableConfig?.answered_percentage_yellow_active
    ) {
     return "text-yellow-500 bg-yellow-100 font-semibold";
    } else {
     if (
      value > this.pbx3cxTableConfig?.answered_percentage_green_value &&
      this.pbx3cxTableConfig?.answered_percentage_green_active
     ) {
      return "text-green-500 bg-green-200 font-semibold";
     }
    }
   },
   getDurationAvgAnswerColor(value) {
    if (
     value > this.pbx3cxTableConfig?.duration_avg_answer_red_value &&
     this.pbx3cxTableConfig?.duration_avg_answer_red_active
    ) {
     return "text-red-500 bg-red-200 font-semibold bg-red-200";
    } else if (
     value > this.pbx3cxTableConfig?.duration_avg_answer_orange_value &&
     value <= this.pbx3cxTableConfig?.duration_avg_answer_red_value &&
     this.pbx3cxTableConfig?.duration_avg_answer_orange_active
    ) {
     return "text-orange-600 bg-orange-200 font-semibold";
    } else if (
     value > this.pbx3cxTableConfig?.duration_avg_answer_yellow_value &&
     value <= this.pbx3cxTableConfig?.duration_avg_answer_orange_value &&
     this.pbx3cxTableConfig?.duration_avg_answer_yellow_active
    ) {
     return "text-yellow-500 bg-yellow-100 font-semibold";
    } else {
     if (
      value <= this.pbx3cxTableConfig?.duration_avg_answer_green_value &&
      this.pbx3cxTableConfig?.duration_avg_answer_green_active
     ) {
      return "text-green-500 bg-green-200 font-semibold";
     }
    }
   },
   getDurationAvgCallColor(value) {
    if (
     value > this.pbx3cxTableConfig?.duration_avg_call_red_value &&
     this.pbx3cxTableConfig?.duration_avg_call_red_active
    ) {
     return "text-red-500 bg-red-200 font-semibold bg-red-200";
    } else if (
     value > this.pbx3cxTableConfig?.duration_avg_call_orange_value &&
     value <= this.pbx3cxTableConfig?.duration_avg_call_red_value &&
     this.pbx3cxTableConfig?.duration_avg_call_orange_active
    ) {
     return "text-orange-600 bg-orange-200 font-semibold";
    } else if (
     value > this.pbx3cxTableConfig?.duration_avg_call_yellow_value &&
     value <= this.pbx3cxTableConfig?.duration_avg_call_orange_value &&
     this.pbx3cxTableConfig?.duration_avg_call_yellow_active
    ) {
     return "text-yellow-500 bg-yellow-100 font-semibold";
    } else {
     if (
      value <= this.pbx3cxTableConfig?.duration_avg_call_green_value &&
      this.pbx3cxTableConfig?.duration_avg_call_green_active
     ) {
      return "text-green-500 bg-green-200 font-semibold";
     }
    }
   },
   getRatioSolicitationsColor(value) {
    if (
     value > this.pbx3cxTableConfig?.ratio_solicitations_red_value &&
     this.pbx3cxTableConfig?.ratio_solicitations_red_active
    ) {
     return "text-red-500 bg-red-200 font-semibold";
    } else if (
     value > this.pbx3cxTableConfig?.ratio_solicitations_orange_value &&
     value <= this.pbx3cxTableConfig?.ratio_solicitations_red_value &&
     this.pbx3cxTableConfig?.ratio_solicitations_orange_active
    ) {
     return "text-orange-600 bg-orange-200 font-semibold";
    } else if (
     value > this.pbx3cxTableConfig?.ratio_solicitations_yellow_value &&
     value <= this.pbx3cxTableConfig?.ratio_solicitations_orange_value &&
     this.pbx3cxTableConfig?.ratio_solicitations_yellow_active
    ) {
     return "text-yellow-500 bg-yellow-100 font-semibold";
    } else {
     if (
      value <= this.pbx3cxTableConfig?.ratio_solicitations_green_value &&
      this.pbx3cxTableConfig?.ratio_solicitations_green_active
     ) {
      return "text-green-500 bg-green-200 font-semibold";
     }
    }
   },
   setSorting(sort, header) {
    let sendSort = sort;
    //  console.log(
    //   "setSorting ~ this.waiting_duration_option:",
    //   this.waiting_duration_option
    //  );
    if (sort === "duration_total") {
     sendSort = sort + "_" + this.talking_duration_option;
    }
    if (sort === "waiting_total") {
     sendSort = sort + "_" + this.waiting_duration_option;
    }
 
    this.$emit("sort", sendSort);
    this.activeSorting = sort;
   },
   focusInputQueue() {
    if (this.$refs.inputSearchQueue) {
     this.$refs.inputSearchQueue[0].focus();
    }
   },
   focusInputQueueName() {
    if (this.$refs.inputSearchQueueName) {
     this.$refs.inputSearchQueueName[0].focus();
    }
   },
   focusInputExtension() {
    if (this.$refs.inputSearchExtension) {
     this.$refs.inputSearchExtension[0].focus();
    }
   },
   focusInputExtensionName() {
    if (this.$refs.inputSearchExtensionName) {
     this.$refs.inputSearchExtensionName[0].focus();
    }
   },
  },
  watch: {
   selectedPeople: function (val) {
    this.$emit("update:selectedPeople", val);
   },
   assignedFilterQueue: function (val) {
    if (val && val.length > 0) {
     this.$emit("update:searchQueue", val);
    } else {
     this.$emit("update:searchQueue", null);
    }
   },
   assignedFilterQueueName: function (val) {
    if (val && val.length > 0) {
     this.$emit("update:searchQueueName", val);
    } else {
     this.$emit("update:searchQueueName", null);
    }
   },
   assignedFilterExtension: function (val) {
    if (val && val.length > 0) {
     this.$emit("update:searchExtension", val);
    } else {
     this.$emit("update:searchExtension", null);
    }
   },
   assignedFilterExtensionName: function (val) {
    if (val && val.length > 0) {
     this.$emit("update:searchExtensionName", val);
    } else {
     this.$emit("update:searchExtensionName", null);
    }
   },
  },
  mounted() {
   if (this.searchQueue) {
    this.assignedFilterQueue = this.searchQueue;
   }
   if (this.searchQueueName) {
    this.assignedFilterQueueName = this.searchQueueName;
   }
   if (this.searchExtension) {
    this.assignedFilterExtension = this.searchExtension;
   }
   if (this.searchExtensionName) {
    this.assignedFilterExtensionName = this.searchExtensionName;
   }
  },
  computed: {
   ...mapGetters([
    "hostName",
    "hostTimeZone",
    "pbxPeriodSelected",
    "pbxElements",
    "pbxMap",
    "pbx3cxTableConfig",
   ]),
  },
 };
 </script>
 
 <style scoped>
 .max__body__width {
  max-width: 80px;
 }
 select {
  padding-right: 1.5rem !important;
 }
 </style>
 